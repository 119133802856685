.gm-style-mtc > button, .gmnoprint > div, .gm-fullscreen-control {
  background-color: rgb(20,20,20) !important;
  color: white !important;
}

.introjs-helperLayer {
  background-color: rgba(255,255,255, .3);
  pointer-events: none;
}

.introjs-fixParent {
  z-index: 9999996 !important;
}
